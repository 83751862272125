import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"

import { Arrow } from "../../assets/svg"
import { CoverButton } from "../../elements"
import { CoverType } from "../../elements/CoverButton"

import ProductHeader, { HeaderProps } from "../ProductHeader"

interface Props extends HeaderProps {
  color: string
  backArrow?: boolean
  coverType?: CoverType
  button?: React.ReactElement
}

const ProductArticleExtraContent: React.FC<Props> = ({
  color,
  children,
  coverType,
  className,
  backArrow = true,
  button = null,
  ...props
}) => {
  return (
    <Container color={color} className={className}>
      <ProductHeader className="productHeader" {...props}>
        {backArrow && (
          <BackButton to="/products/" arrowColor={color}>
            <Arrow />
            <span>back to products</span>
          </BackButton>
        )}
        {children}
        <Footer className="productFooter">
          {button ? (
            button
          ) : (
            <CoverButton
              coverType={coverType}
              filled
              buttonColor={color}
              textColor="#fff"
              label="Get Covered"
            />
          )}
        </Footer>
      </ProductHeader>
    </Container>
  )
}

export default ProductArticleExtraContent

const Container = styled.div<{ color: string }>`
  padding-bottom: 60px;

  .productHeader {
    gap: 100px;

    h1,
    h2 {
      font-size: 28px;
      line-height: 40px;
      max-width: 100vw;
      padding: 46px 20px;
    }

    h1 {
      background-color: ${props => props.color};
      color: #fff;
    }

    p,
    h3 {
      padding-left: 20px;
      padding-right: 20px;
    }

    .coverButton {
      margin: 40px 20px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
      h1 {
        font-size: 48px;
        line-height: 62px;
      }

      .coverButton {
        margin: 0 auto;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      padding-bottom: 0;

      h1,
      h2 {
        padding: 46px 80px;
      }

      h1 {
        background-color: ${props => props.color};
        color: #fff;
      }

      p,
      h3 {
        padding-left: 80px;
        padding-right: 80px;
      }

      p,
      h2 {
        font-size: 30px;
        line-height: 48px;
      }

      .coverButton {
        margin: 90px auto 70px auto;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      h1 {
        font-size: 52px;
        line-height: 72px;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      grid-template-columns: minmax(500px, 580px) 1fr;

      h1,
      h2,
      h3,
      p {
        padding-left: 112px;
      }

      p,
      h2 {
        font-size: 30px;
        line-height: 48px;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 60px 0 0;
  }
`

const BackButton = styled(Link)<{ arrowColor: string }>`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 24px;
  grid-row: 1;
  margin-left: 20px;

  svg {
    transform: rotateZ(180deg);
    width: 25px;

    path {
      ${({ arrowColor }) => css`
        fill: ${arrowColor};
      `}
    }
  }

  span {
    font-size: 18px;
    line-height: 18px;
    margin-top: 4px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 60px;
  }
`

const Footer = styled.div`
  padding: 0 20px;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 80px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;
  }
`
