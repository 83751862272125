import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "./theme"
interface Props {
  element?: any
}

const wrapRootElement: React.FC<Props> = ({ element }: Props) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

export default wrapRootElement
