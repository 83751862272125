import React from "react"
import styled from "styled-components"

const Loader: React.FC = () => {
  return (
    <Container>
      <div className="loader">
        <span></span>
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: auto;
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  justify-content: center;
  align-content: center;
  .loader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    -webkit-animation: loader-1-1 4.8s linear infinite;
    animation: loader-1-1 4.8s linear infinite;
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      clip: rect(0, 32px, 32px, 16px);
      -webkit-animation: loader-1-2 1.2s linear infinite;
      animation: loader-1-2 1.2s linear infinite;
      ::after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        clip: rect(0, 32px, 32px, 16px);
        border: 3px solid #00427c;
        border-radius: 50%;
        -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1)
          infinite;
        animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      }
    }
  }
  &:hover {
    .loader {
      span {
        ::after {
          border: 3px solid #fff;
          transition: all 0.5s;
        }
      }
    }
  }
  @keyframes loader-1-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-1-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(220deg);
    }
  }
  @keyframes loader-1-3 {
    0% {
      transform: rotate(-140deg);
    }
    50% {
      transform: rotate(-160deg);
    }
    100% {
      transform: rotate(140deg);
    }
  }
`

export default Loader
