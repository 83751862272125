import React from "react"
import { MailIcon, PhoneIcon } from "../../assets/svg"
import { IconLink, StyledButton } from "../../elements"

const ContactInfo = (): JSX.Element => {
  return (
    <>
      <IconLink icon={<PhoneIcon />} link="tel:+27861520520" />
      <IconLink icon={<MailIcon />} link="mailto:info@workerslife.co.za" />
      <StyledButton
        label="Build My Cover"
        link="/products"
        buttonColor="#00427C"
        textColor="#000"
      />
    </>
  )
}

export default ContactInfo
