import { MenuType } from "../components/types"

import theme from "../theme"

const PATH_PREFIX = "/products/"

export const PRODUCT_URLS = {
  LIFE_AND_LIVE_BENEFIT: `${PATH_PREFIX}life-and-live-secure-benefit`,
  GROUP_FUNERAL_BENEFIT: `${PATH_PREFIX}group-funeral-benefit`,
  COMPREHENSIVE_FUNERAL_BENEFIT: `${PATH_PREFIX}comprehensive-funeral-benefit`,
  PLATINUM_PLUS_FUNERAL_BENEFIT: `${PATH_PREFIX}platinum-plus-funeral-benefit`,
  POPCRU_25_FUNERAL_BENEFIT: `${PATH_PREFIX}popcru-25-funeral-benefit`,
  COMPREHENSIVE_LEGAL_INSURANCE: `${PATH_PREFIX}comprehensive-legal-insurance`,
}

export const MENU_LINKS: MenuType[] = [
  {
    color: theme.palette.custom.blue,
    name: "Life Insurance Products",
    items: [
      {
        label: "Life and Live Secure Benefit",
        link: PRODUCT_URLS.LIFE_AND_LIVE_BENEFIT,
      },
    ],
  },
  {
    color: theme.palette.custom.yellow,
    name: "Funeral Products",
    items: [
      {
        label: "Group Funeral Benefit",
        link: PRODUCT_URLS.GROUP_FUNERAL_BENEFIT,
      },
      {
        label: "Comprehensive Funeral Benefit",
        link: PRODUCT_URLS.COMPREHENSIVE_FUNERAL_BENEFIT,
      },
      {
        label: "Platinum Plus Funeral Benefit",
        link: PRODUCT_URLS.PLATINUM_PLUS_FUNERAL_BENEFIT,
      },
      {
        label: "POPCRU 25 Funeral Benefit",
        link: PRODUCT_URLS.POPCRU_25_FUNERAL_BENEFIT,
      },
    ],
  },
  {
    color: theme.palette.custom.green,
    name: "Short-term Insurance Products",
    items: [
      {
        label: "Comprehensive Legal Insurance",
        link: PRODUCT_URLS.COMPREHENSIVE_LEGAL_INSURANCE,
      },
    ],
  },
]

export const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/workerslifeSA",
  twitter: "https://twitter.com/WorkersLifeSA",
}
