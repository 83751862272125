import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import SwiperCore, { Autoplay, Controller, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"

import CoverButton, { CoverType } from "../elements/CoverButton"

const TIME_PER_SLIDE = 10 * 1000

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color: string
  title: string
  coverType?: CoverType
  content: {
    image: JSX.Element
    topic?: string
    label: string
  }[]
  button?: React.ReactElement
  showButton?: boolean
  reverse?: boolean
  description?: string | null
}

const Slider: React.FC<Props> = ({
  color,
  title,
  coverType = undefined,
  className = "",
  content,
  button = null,
  showButton = false,
  reverse = false,
  description = null,
}) => {
  const images = content.map(item => item.image)

  const [currentSlide, setCurrentSlide] = useState(0)

  SwiperCore.use([Autoplay, Pagination, Controller])
  const [swiperA, setSwiperA] = useState<SwiperCore | null>(null)
  const [swiperB, setSwiperB] = useState<SwiperCore | null>(null)

  return (
    <Container reverse={reverse}>
      <div className="imageContainer">
        <Swiper
          initialSlide={0}
          onSwiper={swiper => setSwiperB(swiper)}
          controller={{ control: swiperA || undefined }}
          loop={true}
          draggable={true}
          grabCursor={true}
          simulateTouch
          autoplay={false}
        >
          {images.map((image, index) => (
            <SwiperSlide key={`image-${index}`}>{image}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      <TextContainer
        className={className}
        cols={content.length}
        color={color}
        activeIndex={currentSlide}
      >
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <Swiper
          initialSlide={0}
          onSwiper={swiper => setSwiperA(swiper)}
          controller={{ control: swiperB || undefined }}
          loop={true}
          draggable={true}
          grabCursor={true}
          simulateTouch
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `
                <div class="${className}">
                  <div class="progress"></div>
                </div>`
            },
          }}
          autoplay={{
            delay: TIME_PER_SLIDE,
            disableOnInteraction: false,
          }}
          onActiveIndexChange={swiper => setCurrentSlide(swiper.realIndex)}
        >
          {content.map((item, index) => (
            <SwiperSlide key={`label-${index}`}>
              <>
                {item.topic && <p className="topic">{item.topic}</p>}
                <p>{item.label}</p>
              </>
            </SwiperSlide>
          ))}
        </Swiper>
        {showButton && (
          <Footer>
            {button ? (
              button
            ) : (
              <CoverButton
                filled
                buttonColor={color}
                textColor="#fff"
                coverType={coverType}
              />
            )}
          </Footer>
        )}
      </TextContainer>
    </Container>
  )
}

export default Slider

const FillProgress = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

const Container = styled.div<{ reverse: boolean }>`
  display: grid;
  gap: 120px;
  grid-template-columns: 100%;
  padding: 30px;

  .imageContainer {
    display: none;
    max-width: 676px;
    object-fit: cover;
    width: 100%;
    grid-column: ${props => (props.reverse ? "2" : "1")};
    grid-row: 1;

    img {
      display: block;
      height: 100%;
      object-fit: fill;
      width: 100%;
    }
  }

  .slider {
    margin: 0 auto;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: ${props =>
      props.reverse
        ? "minmax(40%, 460px) minmax(0, 676px)"
        : "minmax(0, 676px) minmax(40%, 460px)"};
    padding: 70px 60px;

    .slider {
      margin: 0;
    }

    .imageContainer {
      display: block;
    }
  }
`

const TextContainer = styled.div<{
  cols: number
  color: string
  activeIndex: number
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  max-width: 460px;
  width: 100%;

  .swiper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .swiper-wrapper {
    margin-top: 40px;

    .topic {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .swiper-pagination {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(${props => props.cols}, 1fr);
    height: max-content;
    top: 0;

    .swiper-pagination-bullet {
      background-color: #c4c4c4;
      border-radius: 0;
      cursor: pointer;
      display: inline-flex;
      height: 4px;
      margin: 0 !important;
      max-width: 123px;
      opacity: 1;
      position: relative;
      width: 100%;

      &:nth-child(${({ activeIndex }) => `-n + ${activeIndex}`}) {
        background-color: ${props => props.color};
      }

      &.swiper-pagination-bullet-active .progress {
        animation: ${FillProgress} ${TIME_PER_SLIDE}ms linear forwards;
      }

      .progress {
        background-color: ${props => props.color};
        height: 100%;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    gap: 62px;

    .swiper-wrapper {
      margin-top: 62px;
    }
  }
`

const Title = styled.p`
  font-family: "PlayfairDisplay";
  font-size: 26px;
  font-weight: 800;
  line-height: 34px;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 48px;
    line-height: 62px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 52px;
    line-height: 69px;
  }
`

const Footer = styled.div`
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    align-self: flex-end;
    display: flex;
    margin-top: 40px;
    width: max-content;
  }
`

const Description = styled.span`
  font-size: 18px;
  line-height: 32px;
  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 28px;
    line-height: 38px;
  }
`
