import React from "react"

import StyledButton, { BasicStyledButtonProps } from "./StyledButton"

const BUILD_COVER_LINK = process.env.BUILD_COVER_LINK || ""

export enum CoverType {
  LIFE_AND_LIVE_SECURE = "sales",
  GROUP_FUNERAL = "group-funeral-cover",
  COMPREHENSIVE_FUNERAL = "comprehensive-funeral-cover",
  PLATINUM_PLUS_LIFE = "platinum-plus-life-cover",
  PLATINUM_PLUS_TERM = "platinum-plus-term-cover",
  POPCRU_FUNERAL = "funeral-cover/popcru",
  COMPREHENSIVE_LEGAL = "legal-cover",
  ALL = "products",
}

interface Props extends BasicStyledButtonProps {
  coverType?: string
  label?: string
}

const CoverButton: React.FC<Props> = ({
  buttonColor,
  textColor,
  filled,
  altColor,
  coverType,
  label = null,
}) => {
  const getButtonLink = (coverType: string | undefined) => {
    if (coverType === undefined) return "/contact-us"
    if (coverType === CoverType.ALL) {
      return "/products"
    } else {
      return `${BUILD_COVER_LINK}/${
        coverType !== CoverType.LIFE_AND_LIVE_SECURE ? `buy/` : ""
      }${coverType}`
    }
  }
  return (
    <StyledButton
      className="coverButton"
      label={
        label || (coverType !== undefined ? "Build My Cover" : "Contact Us")
      }
      buttonColor={buttonColor}
      textColor={textColor}
      altColor={altColor}
      link={getButtonLink(coverType)}
      filled={!!filled}
    />
  )
}

export default CoverButton
