import React, { useEffect, useState } from "react"
import styled from "styled-components"

interface InputLabelProps {
  shrink: boolean
}

interface ErrorProps {
  showError: boolean
}

const TextField = ({
  field,
  form: { touched, errors, dirty, values },
  ...props
}) => {
  const shrink = values[field.name].length > 0 && dirty
  const showError: boolean = touched[field.name] && errors[field.name]
  return (
    <InputRoot showError={showError}>
      <InputBase showError={showError}>
        <InputLabel shrink={shrink}>{props.label}</InputLabel>
        <Input type="text" {...field} {...props} />
      </InputBase>
      <div className="error">{errors[field.name] ?? "Required"}</div>
    </InputRoot>
  )
}

const InputBase = styled.div<ErrorProps>`
  border-bottom: solid
    ${props => (props.showError ? "2px rgb(244, 67, 54)" : "1px black")};
  padding-left: 15px;
  position: relative;
  &:after {
    border-bottom: 2px solid #00427c;
    left: 0px;
    bottom: -2px;
    content: "";
    position: absolute;
    right: 0px;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    z-index: 3;
  }
  &:focus-within {
    &:after {
      transform: scaleX(1) translateX(0px);
    }
  }
`

const InputRoot = styled.div<ErrorProps>`
  .error {
    font-size: 14px;
    color: rgb(244, 67, 54);
    padding-left: 15px;
    visibility: ${props => (props.showError ? "visible" : "hidden")};
  }
`

const Input = styled.input`
  position: relative;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  font-size: 22px;
  padding: 0 0 20px 0;
  margin-top: 15px;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
`

const InputLabel = styled.label<InputLabelProps>`
  font-size: 22px;
  padding: 20px 0;
  color: #b0b0b0;
  position: absolute;
  z-index: 2;
  user-select: none;
  pointer-events: none;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms;
  ${InputBase}:focus-within & {
    transform: translateY(-30%) scale(0.75);
  }
  ${props => (props.shrink ? "transform: translateY(-30%) scale(0.75)" : "")};
`

export default TextField
