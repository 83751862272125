import React from "react"
import styled from "styled-components"

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  icon: JSX.Element
  link: string
  label?: string
}

const IconLink: React.FC<Props> = ({ icon, link, label }) => {
  return (
    <Container href={link}>
      {icon}
      {label && <span>{label}</span>}
    </Container>
  )
}

export default IconLink

const Container = styled.a`
  align-items: center;
  color: #b0b0b0;
  display: flex;

  span {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.06em;
    line-height: 14px;
    text-transform: capitalize;
    margin: 2px 0 0 12px;
  }

  svg {
    height: 26px;
    width: auto;
  }
`
