import React from "react"
import styled, { css } from "styled-components"

import { Arrow } from "../assets/svg"
import theme from "../theme"

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  label: string
  link: string
  arrowColor: string
}

const StyledLink: React.FC<Props> = ({
  label,
  link,
  arrowColor,
  className,
}) => {
  return (
    <Container className={className} href={link} arrowColor={arrowColor}>
      <span className="styled-link-label">{label}</span>
      <Arrow />
    </Container>
  )
}

export default StyledLink

const Container = styled.a<{
  arrowColor: string
}>`
  align-items: center;
  color: #000;
  display: flex;
  font-weight: 400;

  span {
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    max-width: 15rem;
    @media screen and (max-width: ${theme.maxBreakpoints.sm}) {
      width: 100%;
    }
  }

  svg {
    margin-left: 15px;
    width: 20px;

    path {
      ${({ arrowColor }) => css`
        fill: ${arrowColor};
      `}
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    span {
      font-size: 20px;
      line-height: 27px;
      max-width: calc(100% - 42px);
    }

    svg {
      width: 26px;
    }
  }
`
