import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"
import { ColorTilesRevamped, ProductTilesWrapper } from "."
import { CoinPileIcon } from "../assets/svg"

interface Props {
  color: string
  imageData: any
  details: {
    opacity: string
    benefits: {
      label: string
      text: string
    }[]
  }[]
}

const ProductBreakdown: React.FC<Props> = ({
  color,
  imageData,
  details,
}): JSX.Element => {
  return (
    <Container>
      <ProductTilesWrapper color={color} tileType="icon">
        <BackgroundImage fluid={imageData} preserveStackingContext>
          <ColorTilesRevamped
            tiles={details.map(detail => ({
              tileType: "text-with-icon",
              color: color,
              icon: <CoinPileIcon />,
              ...detail,
            }))}
          />
        </BackgroundImage>
      </ProductTilesWrapper>
    </Container>
  )
}

export default ProductBreakdown

const Container = styled.div`
  h2 {
    margin-bottom: 78px;
    text-align: center;
  }
`
const Disclaimer = styled.p`
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 25px;

  a {
    font-family: "SofiaPro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
