import React from "react"
import styled from "styled-components"

import Layout from "./components/Layout"

interface Props {
  element?: any
  props?: any
}

const wrapPageElement: React.FC<Props> = ({ element, props }: Props) => {
  return (
    <Container>
      <Layout>{element}</Layout>
    </Container>
  )
}

export default wrapPageElement

const Container = styled.div`
  min-height: 100vh;
`
