import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"

import StyledLink from "../elements/StyledLink"
import { MenuType } from "./types"

import { ChevronThick } from "../assets/svg"
import theme from "../theme"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  properties: MenuType
  dropDown?: boolean
  noIcon?: boolean
}

const Menu: React.FC<Props> = ({
  dropDown = false,
  noIcon = false,
  properties,
  className,
  children,
}) => {
  const { color, name, items } = properties
  const [open, setOpen] = useState(false)

  const itemsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const items = itemsRef.current
    if (dropDown && items) {
      items.style.maxHeight = open ? `${items.scrollHeight + 34}px` : "0"
    }
  }, [open])

  return (
    <Container className={className}>
      <Header
        color={color}
        dropdown={dropDown}
        onClick={() => setOpen(open => !open)}
        className="header"
      >
        <HeaderText>{name}</HeaderText>
        <HeaderIcon>
          {dropDown && !noIcon ? (
            <Icon color={color} flip={!open}>
              <ChevronThick />
            </Icon>
          ) : null}
        </HeaderIcon>
      </Header>
      {items && (
        <Items
          ref={itemsRef}
          className={`menuItems ${!dropDown ? "" : open ? "open" : "closed"}`}
        >
          {items.map((item, index) => (
            <StyledLink
              key={index}
              label={item.label}
              link={item.link}
              arrowColor={color}
            />
          ))}
        </Items>
      )}
      {children && <Nested>{children}</Nested>}
    </Container>
  )
}

export default Menu

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const HeaderIcon = styled.div`
  display: inline-block;
  @media screen and (max-width: ${theme.maxBreakpoints.xs}) {
    width: 25%;
  }
`
const HeaderText = styled.div`
  display: inline-block;
  @media screen and (max-width: ${theme.maxBreakpoints.xs}) {
    width: 75%;
  }
`

const Header = styled.div<{ color: string; dropdown: boolean }>`
  color: ${props => props.color};
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 20px;

  ${({ dropdown }) =>
    dropdown &&
    css`
      cursor: pointer;
    `}

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 26px;
    line-height: 37px;
  }
`

const Icon = styled.span<{ color: string; flip?: boolean }>`
  line-height: 0;
  margin: 0 0 0 20px;

  svg {
    margin: 0;

    ${props =>
      props.flip &&
      css`
        transform: rotateZ(180deg);
      `}

    path {
      fill: ${props => props.color};
    }
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  transition: all 0.5s ease-in-out;

  &.open {
    transition: all 0.5s ease-in-out;
  }

  > a:last-of-type {
    margin-bottom: 24px;
  }
`

const Nested = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  transition: all 0.5s ease-in-out;

  &.open {
    transition: all 0.5s ease-in-out;
  }
`
