import { css } from "styled-components"

export const MobileMonotoneTiles = css<{ color: string }>`
  .textOnly,
  .withIcon {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1px;
      background: linear-gradient(to right, #ffffff00, #ffffff50, #ffffff00);
    }
  }
`

export const IconTiles = css`
  .withIcon span {
    max-width: 370px;
    text-align: center;
  }
`

export const TextTiles = css`
  .textOnly span {
    display: flex;
    font-family: "PlayfairDisplay";
    font-size: 30px;
    font-style: italic;
    font-weight: 550;
    line-height: 40px;
    margin: 0 auto;
    max-width: 380px;

    @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 52px;
      line-height: 69px;
    }
  }
`
