import React from "react"
import styled from "styled-components"
import { ContactInfo } from "."
import { Menu } from ".."

import { MENU_LINKS } from "../../constants/urls"
import { StyledLink } from "../../elements"

const MobileMenu: React.FC = () => (
  <Container>
    <div>
      <StyledLink
        label="Why Workerslife"
        link="/why-workerslife"
        arrowColor="#000"
        className="navLink"
      />
      <Menu
        className="offersMenu"
        dropDown
        noIcon
        properties={{ color: "#000", name: "What We Offer" }}
      >
        {MENU_LINKS.map((productList, index) => (
          <Menu
            key={`offers-menu-${index}`}
            dropDown
            noIcon
            properties={productList}
          />
        ))}
      </Menu>
      <StyledLink
        label="Insurance Basics"
        link="/insurance-basics"
        arrowColor="#000"
        className="navLink"
      />
      <StyledLink
        label="Blog"
        link="https://blog.workerslife.co.za/"
        arrowColor="#000"
        className="navLink"
      />
    </div>
    <Footer>
      <ContactInfo />
    </Footer>
  </Container>
)

export default MobileMenu

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-rows: 1fr max-content;
  height: 100%;

  .navLink {
    margin-bottom: 20px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  }
`

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 20px;
  justify-content: space-between;

  .coverButton {
    width: 100%;
  }

  @media screen and (min-width: 430px) {
    justify-content: flex-end;

    .coverButton {
      width: max-content;
    }
  }
`
