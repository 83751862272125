import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"

import { Add as CloseIcon } from "../assets/svg"

const ANIMATION_DURATION = 800

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  onClose: () => void
}

const Modal: React.FC<Props> = ({ show, className, children, onClose }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (show && !visible) {
      setVisible(true)
    } else if (!show && visible) {
      setTimeout(() => {
        setVisible(false)
      }, ANIMATION_DURATION)
    }
  }, [show])

  const closeModal = (event: React.MouseEvent) => {
    event.stopPropagation()
    onClose()
  }

  return visible ? (
    <Container className={`${show ? "showing" : "hidden"} ${className}`}>
      <Backdrop className="backdrop" onClick={event => closeModal(event)} />
      <Content className="contentContainer">
        <CloseButton onClick={event => closeModal(event)}>
          <CloseIcon />
        </CloseButton>
        {children}
      </Content>
    </Container>
  ) : null
}

export default Modal

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% { 
    opacity: 0.8;
  }
`

const FadeOut = keyframes`
  0% { 
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`

const SlideIn = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
`

const SlideOut = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% { 
    transform: translateY(50px);
    opacity: 0;
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &.showing {
    .backdrop {
      animation: ${FadeIn} ${ANIMATION_DURATION}ms ease-in-out;
    }
    .contentContainer {
      animation: ${SlideIn} ${ANIMATION_DURATION}ms ease-in-out;
    }
  }

  &.hidden {
    .backdrop {
      animation: ${FadeOut} ${ANIMATION_DURATION}ms ease-in-out;
    }
    .contentContainer {
      animation: ${SlideOut} ${ANIMATION_DURATION}ms ease-in-out;
    }
  }

  .backdrop,
  .contentContainer {
    animation-fill-mode: forwards !important;
  }
`

const Backdrop = styled.div`
  background-color: #000;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`

const Content = styled.div`
  align-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-width: 100vw;
  overflow-y: scroll;
  padding: 40px;
  width: 100vw;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.sm}) and (min-height: 500px) {
    border-radius: 4px;
    height: max-content;
    padding: 100px;
    width: max-content;
  }
`

const CloseButton = styled.button`
  background-color: transparent;
  padding-top: 20px;
  border: none;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    transform: rotateZ(45deg);

    rect {
      fill: #000;
    }
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.sm}) and (min-height: 500px) {
    display: none;
  }
`
