import React from "react"
import styled from "styled-components"
import { CoverButton } from "../elements"

interface SectionHeaderProps {
  title: string
  subTitle?: string
  buttonColor?: string
  coverType?: string
  showButton?: boolean
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subTitle,
  buttonColor,
  coverType,
  showButton = true,
}) => {
  return (
    <Container>
      <h2>{title}</h2>
      {subTitle && <p>{subTitle}</p>}
      {showButton ?? (
        <CoverButton
          filled
          buttonColor={buttonColor}
          textColor="#fff"
          coverType={coverType}
          label="Explore Our Insurance Products"
        />
      )}
    </Container>
  )
}
export default SectionHeader

const Container = styled.div`
  margin: 60px auto;
  padding: 0 20px;

  h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }

  .coverButton {
    margin: 0 auto;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    margin: 90px auto;

    p {
      font-size: 26px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    .coverButton {
      margin: 80px auto 0px auto;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
  }
`
