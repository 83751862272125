import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { DESKTOP_NAV_HEIGHT, MOBILE_NAV_HEIGHT } from "../../constants/sizes"
import { MainMenu, MobileMenu, ProductMenu } from "."

const NavBar: React.FC = () => {
  const { pathname } = useLocation()
  const [offersOpen, setOffersOpen] = useState(false)

  useEffect(() => {
    setOffersOpen(false)
  }, [pathname])

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <Wrapper open={offersOpen}>
      <Container id="mainMenu">
        <MainMenu
          offersOpen={offersOpen}
          openOffers={() => setOffersOpen(offersOpen => !offersOpen)}
          toggleMobileMenu={() => setMobileMenuOpen(open => !open)}
        />
      </Container>
      <Container id="mobileMenu" className={mobileMenuOpen ? "open" : "closed"}>
        <MobileMenu />
      </Container>
      <Container id="productMenu">
        <ProductMenu />
      </Container>
    </Wrapper>
  )
}

export default NavBar

const Wrapper = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0;
  z-index: 100;

  ${props => !props.open && { width: "0px" }};

  #mainMenu {
    box-shadow: ${props =>
      props.open ? "0px 4px 21px rgba(0, 0, 0, 0.06)" : "none"};
    z-index: 101;
  }

  #mobileMenu {
    height: calc(100vh - ${MOBILE_NAV_HEIGHT});
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  #productMenu {
    display: none;
    max-height: calc(100vh - ${DESKTOP_NAV_HEIGHT});
    overflow-y: scroll;
    position: absolute;
    transition: transform 1s;
    z-index: -1;

    ::-webkit-scrollbar {
      display: none;
    }

    ${props =>
      props.open
        ? css`
            transform: translateY(0);
          `
        : css`
            transform: translateY(-100%);
          `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    #productMenu {
      display: block;
    }

    #mobileMenu {
      display: none;
    }
  }
`

const Container = styled.nav`
  background-color: #fff;
  display: grid;
  padding: 40px 20px;
  transition: transform 1s ease-out;
  width: 100vw;

  &.open {
    transform: translateX(0%);
  }

  &.closed {
    transform: translateX(100%);
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 40px 58px;
  }
`
