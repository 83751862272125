import React from "react"
import styled, { css } from "styled-components"

export interface FAQListItemsTextProps {
  id: number
  numbered?: boolean
  description: string
  subtext?: string
  points?: string[]
}

interface FAQListItemsProps {
  text: FAQListItemsTextProps
}

const FAQText: React.FunctionComponent<FAQListItemsProps> = ({ text }) => {
  const { id, numbered, description, subtext, points } = text

  return (
    <Container>
      <DescriptionNumber
        numbered={numbered || false}
        className="descriptionNumber"
      >
        {numbered && <div>{id}.</div>}
        <DescriptionText
          numbered={numbered || false}
          className="descriptionText"
        >
          {description.split(`\n`).map((descriptionText, index) => (
            <p key={`${id}.${index}`}>{descriptionText}</p>
          ))}
        </DescriptionText>
        {subtext && (
          <DescriptionText className="subtext" numbered={false}>
            {subtext}
          </DescriptionText>
        )}
        {(points || [])?.length > 0 && (
          <ul className="bulletContainer">
            {points?.map((bullet, index) => (
              <DescriptionBullet key={`q${id}-a${index}`} numbered={false}>
                <DescriptionBulletText>{bullet}</DescriptionBulletText>
              </DescriptionBullet>
            ))}
          </ul>
        )}
      </DescriptionNumber>
    </Container>
  )
}

export default FAQText

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    div,
    p {
      font-size: 22px;
      line-height: 44px;
    }
  }
`

const DescriptionNumber = styled.div<{ numbered: boolean }>`
  display: grid;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  gap: 10px 0;
  grid-template-columns: 1fr;
  line-height: 39px;
  padding-bottom: 50px;

  ${({ numbered }) =>
    numbered
      ? css`
          grid-template-columns: 25px 1fr;

          .subtext,
          .bulletContainer {
            grid-column: 2;
          }

          .bulletContainer {
            padding-inline-start: 14px;
          }
        `
      : css`
          .bulletContainer {
            padding-inline-start: 20px;
          }
        `}

  .descriptionText,
  .subtext {
    padding-left: 14;
  }

  &:not(:last-child) .descriptionText {
    ${({ numbered }) =>
      !numbered &&
      css`
        padding-bottom: 20px;
      `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    ${({ numbered }) =>
      numbered &&
      css`
        gap: 10px 34px;

        .bulletContainer {
          margin-left: 10px;
        }
      `}
  }
`

const DescriptionText = styled.div<{ numbered: boolean }>`
  display: flex;
  flex-direction: column;
  gap: inherit;

  &,
  p {
    font-style: normal;
    font-weight: 400;
  }

  p:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${props =>
      props.theme.maxBreakpoints.sm}) {
    gap: 20px;
    padding-left: 0;
    padding-right: 0px;

    p:not(:last-of-type) {
      margin-bottom: 0;
    }

    .bulletContainer {
      padding-inline-start: 30px;
    }
  }
`

const DescriptionBullet = styled.li<{ numbered: boolean }>`
  line-height: 39px;
  list-style-type: disc;

  @media only screen and (max-width: ${props =>
      props.theme.maxBreakpoints.sm}) {
    ${props =>
      props.numbered
        ? { padding: "0rem 1rem 0.5rem 3.2rem" }
        : { padding: "0rem 1rem 0.5rem 0.5rem" }}
  }
`

const DescriptionBulletText = styled.div`
  margin-left: 14px;
  word-wrap: break-word;

  @media only screen and (max-width: ${props =>
      props.theme.maxBreakpoints.sm}) {
    margin-left: 0;
    padding-left: 0;
  }
`
