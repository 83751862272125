import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import { DESKTOP_NAV_HEIGHT, MOBILE_NAV_HEIGHT } from "../constants/sizes"
import { GlobalStyles } from "../theme"
import { NavBar, Footer } from "."

interface Props {
  children?: React.ReactElement | JSX.Element
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0, shrink-to-fit=no"
        />
      </Helmet>
      <GlobalStyles />
      <NavBar />
      <main>{children}</main>
      <Footer />
    </Container>
  )
}

export default Layout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;

  main {
    margin: ${MOBILE_NAV_HEIGHT} auto 90px auto;
    max-width: 1440px;
    min-height: 100%;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: ${DESKTOP_NAV_HEIGHT};

      .touchFooter {
        margin-bottom: 20px;
      }
    }
  }
`
