import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { usePreventScroll } from "../../hooks"

interface Props {
  onClick: () => void
}

const MenuButton: React.FC<Props> = ({ onClick }) => {
  const menuButton = useRef<HTMLButtonElement>(null)
  const [menuShowing, setMenuShowing] = useState(false)

  const preventScroll = usePreventScroll()

  useEffect(() => {
    preventScroll(menuShowing)
  }, [menuShowing])

  return (
    <Container
      ref={menuButton}
      className={menuShowing ? "closeButton" : "openButton"}
      onClick={() => {
        setMenuShowing(menuShowing => !menuShowing)
        onClick()
      }}
    >
      <span></span>
      <span></span>
      <span></span>
    </Container>
  )
}

export default MenuButton

const Container = styled.button<{
  buttonTheme?: string
}>`
  background-color: transparent;
  border: none;
  padding: 4px 10px 0 0;
  padding-bottom: 4px;
  z-index: 21;

  > span {
    background-color: #000;
    display: block;
    height: 3px;
    margin: 5px 0px;
    width: 26px;
  }

  &.openButton {
    span {
      transform-origin: 4px 0px;
      transform: rotate(0deg) translate(0px, 0px);
      transition: background-color 0.5s, opacity 0.5s, transform 0.5s;
    }

    span:first-child {
      transform-origin: 0% 0%;
    }

    span:nth-child(2) {
      opacity: 1;
    }

    span:last-child {
      transform-origin: 0% 100%;
    }
  }

  &.closeButton {
    span {
      transition: background-color 0.5s, opacity 0.5s, transform 0.5s;
    }

    span:first-child {
      transform-origin: 0% 0%;
      transform: rotate(45deg) translate(4px, -4px);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:last-child {
      transform-origin: 0% 100%;
      transform: rotate(-45deg) translate(3px, 4.5px);
    }
  }
`
