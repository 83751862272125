import BackgroundImage, { IFluidObject } from "gatsby-background-image"
import React from "react"
import styled, { css } from "styled-components"

type ColorTile = {
  color: string
  opacity?: string
}

type IconTile = {
  icon: JSX.Element
  label: string
} & ColorTile & { tileType: "icon" }

type TextIconTile = {
  icon: JSX.Element
  labelOne: string
  labelTwo?: string
  labelThree?: string
} & ColorTile & { tileType: "text-with-icon" }

type TextTile = {
  header: string
  body?: string
} & ColorTile & { tileType: "text-only" }

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  backgroundImage?: IFluidObject
  tiles: IconTile[] | TextTile[] | TextIconTile[]
}

const ColorTiles: React.FC<Props> = ({
  tiles,
  className,
  title,
  backgroundImage,
}) => {
  const numTiles = tiles.length

  const renderTiles = () => (
    <Container cols={numTiles} className={className}>
      {tiles.map((tile, index) => (
        <Tile
          key={index}
          className={tile.tileType === "text-only" ? "textOnly" : "withIcon"}
        >
          <Color color={tile.color} opacity={tile.opacity} />
          {tile.tileType === "icon" && (
            <>
              {tile.icon}
              <span>{tile.label}</span>
            </>
          )}
          {tile.tileType === "text-with-icon" && (
            <>
              {tile.icon}
              <LabelWrapper>
                <span>
                  <li>{tile.labelOne}</li>
                </span>
                <br />
                {tile.labelTwo && (
                  <span>
                    <li>{tile.labelTwo}</li>
                  </span>
                )}
                <br />
                {tile.labelThree && (
                  <span>
                    <li>{tile.labelThree}</li>
                  </span>
                )}
              </LabelWrapper>
            </>
          )}
          {tile.tileType === "text-only" && (
            <TextOnlyContainer>
              <span>{tile.header}</span>
              {tile.body && <p>{tile.body}</p>}
            </TextOnlyContainer>
          )}
        </Tile>
      ))}
    </Container>
  )

  return (
    <Wrapper>
      <h2>{title}</h2>
      {backgroundImage ? (
        <BackgroundImage fluid={backgroundImage} preserveStackingContext>
          {renderTiles()}
        </BackgroundImage>
      ) : (
        renderTiles()
      )}
    </Wrapper>
  )
}

export default ColorTiles

const Wrapper = styled.div`
  h2 {
    padding: 0 20px;
    text-align: center;
    margin-bottom: 78px;
  }
`

const Container = styled.div<{ cols: number }>`
  display: grid;
  grid-template-rows: repeat(${({ cols }) => cols}, max-content);

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${({ cols }) => Math.ceil(cols / 2)}, 816px);
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}) {
    ${({ cols }) =>
      cols % 2 &&
      css`
        > div:last-of-type {
          grid-column: 1 / span 2;
        }
      `}
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(${({ cols }) => cols}, 1fr);
    grid-template-rows: 613px;
  }
`

const Tile = styled.div`
  color: #fff;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 0;

  span {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  &.withIcon {
    display: grid;
    gap: 60px;
    grid-template-rows: 1fr max-content;
    padding: 80px 20px;

    li {
      text-align: left;
    }

    svg {
      align-self: center;
      display: flex;
      height: 80px;
      margin: 0 auto;
      width: auto;
    }

    span {
      text-align: center;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      padding: 80px 40px;

      svg {
        height: 120px;
      }

      span {
        font-size: 30px;
        line-height: 41px;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      gap: 175px;
      grid-template-rows: 1fr 130px;
      padding: 35px 40px;

      svg {
        align-self: flex-end;
        height: 170px;
      }

      span {
        text-align: left;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      padding: 35px 24px;
    }
  }

  &.textOnly {
    display: flex;
  }
`

const TextOnlyContainer = styled.div`
  width: 100%;
  padding: 80px 30px;

  span {
    font-weight: 900;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    padding-top: 32px;
    font-style: normal;
    text-align: center;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 80px 30px 50px 30px;
  }
`

const Color = styled.div<{ color: string; opacity?: string }>`
  background-color: ${({ color }) => color};
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: -1;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    opacity: ${({ opacity }) => opacity || 0.6};
  }
`
const LabelWrapper = styled.div`
  margin-top: -100px;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 0px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.lg}) {
    span {
      position: absolute;
      left: 0;
      margin-left: 80px;
    }
  }
  li {
    text-indent: -40px;
    margin-left: 40px;
  }
`
