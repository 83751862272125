import React from "react"
import styled, { css } from "styled-components"

import { IconTiles, MobileMonotoneTiles, TextTiles } from "./utils"

type TileType = "icon" | "text-only"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color: string
  tileType: TileType
}

const ProductTilesWrapper: React.FC<Props> = ({
  color,
  tileType,
  children,
  className,
}) => {
  return (
    <Container color={color} tileType={tileType} className={className}>
      {children}
    </Container>
  )
}

export default ProductTilesWrapper

const Container = styled.div<{ color: string; tileType: TileType }>`
  ${props =>
    props.tileType === "icon"
      ? css`
          ${IconTiles}
        `
      : css`
          ${TextTiles}
        `}

  @media screen and (max-width: ${props => props.theme.maxBreakpoints.sm}) {
    ${MobileMonotoneTiles}
  }
`
