/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface PropertyMetaObj {
  property: string
  content: string
}

type NameMetaObj = {
  name: string
  content: string
}

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>

interface Props {
  description?: string
  lang?: string
  meta?: Meta
  title: string
  customSuffix?: string
  noSuffix?: boolean
  image?: string
}
const SEO = ({
  description = "",
  lang = "en",
  title,
  meta = [],
  customSuffix = "",
  noSuffix,
  image = "",
}: Props): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = customSuffix || site.siteMetadata?.title
  const pageImage =
    image || "https://www.workerslife.co.za/icons/icon-384x384.png"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        !noSuffix && defaultTitle ? `%s | ${defaultTitle}` : undefined
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: pageImage,
        },
        {
          property: `twitter:image`,
          content: pageImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
