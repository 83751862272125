import { createGlobalStyle } from "styled-components"

const theme = {
  palette: {
    primary: {
      main: "#0f3665",
    },
    secondary: {
      main: "#0f3665",
      contrastText: "#ffffff",
    },
    error: {
      main: "#dc1f26",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#292a2b",
      secondary: "#0f3665",
    },
    custom: {
      blue: "#00427c",
      yellow: "#f0a949",
      green: "#2ca165",
      red: "#a51b23",
    },
  },
  breakpoints: {
    xs: "400px",
    sm: "600px",
    md: "900px",
    lg: "1200px",
    xl: "1440px",
  },
  maxBreakpoints: {
    xs: "399px",
    sm: "599px",
    md: "899px",
    lg: "1199px",
    xl: "1439px",
  },
}

export default theme

export const GlobalStyles = createGlobalStyle`
  * {
    font-size: 16px;
    line-height: 28px;

    @media screen and (min-width: ${theme.breakpoints.xs}) {
      font-size: 18px;
      line-height: 32px;
    }
    
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  h1 {
    font-size: 36px;
    line-height: 48px;

      @media screen and (min-width: ${theme.breakpoints.xs}) {
      font-size: 48px;
      line-height: 62px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: 86px;
      line-height: 115px;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 40px;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: 52px;
      line-height: 69px;
      margin-bottom: 60px;
    }
  }

  h3 {
    font-size: 40px;
    line-height: 53px;
    margin-bottom: 58px;
  }
`
