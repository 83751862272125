import React from "react"
import styled from "styled-components"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subTitle: string
  titleColour?: "black" | "#2ca165"
  topic?: string
}

const ProductHeader: React.FC<HeaderProps> = ({
  title,
  subTitle,
  children,
  className,
  titleColour = "black",
  topic = "",
}) => {
  if (topic != "") title = title + ":"
  return (
    <Container titleColour={titleColour} className={className}>
      <div className="textContainer">
        <h1>
          {title}{" "}
          {topic && (
            <h2 className="topic">
              <br />
              {topic}
            </h2>
          )}
        </h1>
        <h3>{subTitle}</h3>
        {children}
      </div>
    </Container>
  )
}

export default ProductHeader

const Container = styled.div<{
  titleColour: "black" | "#2ca165"
}>`
  display: flex;

  h3 {
    margin: 30px 0;
    font-size: 24px;
  }

  h1 {
    margin: 55px 0px 10px 0px;
    color: ${props => props.titleColour};
    word-wrap: break-word;
  }

  p {
    font-weight: 300;
    padding-bottom: 30px;
    white-space: normal;
  }

  .textContainer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 100%;
    h1 > h2.topic {
      margin: 0;
      padding: 0;
      font-size: 24px;
    }
  }

  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    p {
      font-size: 26px;
      line-height: 55px;
    }
    h3 {
      font-size: 30px;
    }

    .textContainer {
      h1 > h2.topic {
        font-size: 40px;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    h3 {
      font-size: 36px;
    }
  }
`
