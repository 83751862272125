import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { ContactInfo, MenuButton } from "."

import { Chevron, Logo, LogoSmall, UserIcon } from "../../assets/svg"
import { IconLink } from "../../elements"
import { MenuWrapping } from "./utils"

const LOGIN_LINK = process.env.LOGIN_LINK || ""

interface Props {
  offersOpen: boolean
  openOffers: () => void
  toggleMobileMenu: () => void
}

const MainMenu: React.FC<Props> = ({
  offersOpen,
  openOffers,
  toggleMobileMenu,
}) => (
  <Container>
    <Section>
      <Link to="/">
        <Logo className="largeLogo" />
        <LogoSmall className="smallLogo" />
      </Link>
    </Section>
    <Section className="desktop">
      <UnstyledLink to="/why-workerslife">Why Workerslife</UnstyledLink>
      <UnstyledButton onClick={openOffers}>
        What We Offer{" "}
        <Icon flip={!offersOpen}>
          <Chevron />
        </Icon>
      </UnstyledButton>
      <UnstyledLink to="/insurance-basics">Insurance Basics</UnstyledLink>
      <UnstyledLink to="https://blog.workerslife.co.za/">Blog</UnstyledLink>
    </Section>
    <Section className="desktop">
      <IconLink icon={<UserIcon />} link={LOGIN_LINK} label="LOGIN" />
      <ContactInfo />
    </Section>
    <Section className="mobile">
      <IconLink icon={<UserIcon />} link={LOGIN_LINK} label="LOGIN" />
      <MenuButton onClick={toggleMobileMenu} />
    </Section>
  </Container>
)

export default MainMenu

const Container = styled.div`
  ${MenuWrapping}

  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr max-content;
  z-index: 101;

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr repeat(2, max-content);
  }
`

const Section = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;

  &.desktop {
    display: none;
  }

  .largeLogo {
    display: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .smallLogo {
      display: none;
    }

    .largeLogo {
      display: inline;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    &.desktop {
      display: flex;
    }

    &.mobile {
      display: none;
    }

    &:nth-of-type(2) {
      gap: 40px;
    }
  }
`

const Icon = styled.span<{ flip?: boolean }>`
  line-height: 0;
  margin: 2px 0 0 4px;

  svg {
    height: 10px;
    margin: 0;

    ${props =>
      props.flip &&
      css`
        transform: rotateZ(180deg);
      `}
  }
`

const NavOptionStyling = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`

const UnstyledLink = styled(Link)`
  ${NavOptionStyling}
`

const UnstyledButton = styled.button`
  ${NavOptionStyling}

  background-color: transparent;
  border: none;
  display: inline-block;
`
