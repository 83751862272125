import React from "react"
import styled, { css } from "styled-components"

export interface ArticleProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  image: JSX.Element
  imageLeft?: boolean
  titleColour?: "black" | "#2ca165"
  isSubComponent?: boolean
}

const Article: React.FC<ArticleProps> = ({
  title,
  image,
  imageLeft = false,
  children,
  className,
  titleColour = "black",
  isSubComponent = true,
}) => {
  return (
    <Container
      titleColour={titleColour}
      reverse={imageLeft}
      className={className}
    >
      <div className="textContainer">
        {isSubComponent ? <h2>{title}</h2> : <h1>{title}</h1>}
        {children}
      </div>
      <div className="imageContainer">{image}</div>
    </Container>
  )
}

export default Article

const Container = styled.div<{
  reverse: boolean
  titleColour: "black" | "#2ca165"
}>`
  display: grid;
  gap: 58px;
  grid-auto-flow: column;
  grid-template-columns: 100%;

  h1,
  h2 {
    margin: 55px 0 65px 0;
    color: ${props => props.titleColour};
  }

  p {
    font-weight: 300;
    padding-bottom: 30px;
    white-space: normal;
  }

  .textContainer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 100%;
  }

  .imageContainer {
    display: none;
    height: 300px;
    width: 100%;
    overflow: hidden;

    ${props =>
      props.reverse &&
      css`
        grid-column: 1;
      `}

    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    p {
      font-size: 26px;
      line-height: 55px;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr minmax(500px, 640px);

    .imageContainer {
      display: flex;
      height: 100%;
      justify-content: flex-end;
    }
  }
`
