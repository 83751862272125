import React from "react"
import styled, { css } from "styled-components"

export interface ArticleNoTitleProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: JSX.Element
  imageLeft?: boolean
}

const ArticleNoTitle: React.FC<ArticleNoTitleProps> = ({
  image,
  imageLeft = false,
  children,
  className,
}) => {
  return (
    <Container reverse={imageLeft} className={className}>
      <div className="textContainer">{children}</div>
      <div className="imageContainer">{image}</div>
    </Container>
  )
}

export default ArticleNoTitle

const Container = styled.div<{
  reverse: boolean
}>`
  display: grid;
  gap: 58px;
  grid-auto-flow: column;
  grid-template-columns: 100%;

  p {
    font-weight: 300;
    padding-bottom: 30px;
    white-space: normal;
  }

  .textContainer {
    margin-top: 55px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 100%;
  }

  .imageContainer {
    display: none;
    height: 300px;
    width: 100%;
    overflow: hidden;

    ${props =>
      props.reverse &&
      css`
        grid-column: 1;
      `}

    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    p {
      font-size: 26px;
      line-height: 55px;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr minmax(500px, 640px);

    .imageContainer {
      display: flex;
      height: 100%;
      justify-content: flex-end;
    }
  }
`
