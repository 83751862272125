import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import theme from "../theme"

import { FacebookIcon, FooterLogo, TwitterIcon } from "../assets/svg"
import { SOCIAL_LINKS } from "../constants/urls"

const Container = styled.div`
  width: 100%;
  background-color: #00427c;
  color: white;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  min-height: 530px;
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    padding: 2.5rem 2rem 2rem 2rem;
  }
`

const LogoContainer = styled.div`
  padding-right: 4rem;
`

const Disclaimer = styled.p`
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 11rem;
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.06em;
  a {
    font-size: 18px;
    line-height: 24.16px;
    padding: 0.5rem 0;
    &.icon {
      margin: auto 0;
    }
  }
  span {
    line-height: 24.16px;
    padding-top: 5px;
  }
  span {
    font-size: 18px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      margin: 0;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    max-height: 22rem;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    max-height: 18rem;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    max-height: fit-content;
  }
`

const IconWrapper = styled.div`
  display: flex;
  gap: 50px;
`

const MainWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const Footer: React.FC = () => {
  const ExternalLinkProperties = {
    target: "_blank",
    rel: "noreferrer",
  }

  return (
    <Container>
      <MainWrapper>
        <LogoContainer>
          <Link to="/">
            <FooterLogo />
          </Link>
        </LogoContainer>
        <Wrapper>
          <Link to="/why-workerslife">Why Workerslife</Link>
          <Link to="/products">What We Offer</Link>
          <Link to="/insurance-basics">Insurance Basics</Link>
          <Link to="https://blog.workerslife.co.za/">Blog</Link>
          <Link to="/workerslife-careers">Careers</Link>
          <Link to="https://www.gov.za/Coronavirus" {...ExternalLinkProperties}>
            Covid-19
          </Link>
          <Link to="/compliance">Compliance</Link>
          <Link to="/faqs/#how-to-claim">Claims</Link>
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/faqs">FAQ</Link>
          <div className="flex-column">
            <a href="mailto:info@workerslife.co.za">info@workerslife.co.za</a>
            <a href="tel:+27861520520">+27 861 520 520</a>
            <IconWrapper>
              <a
                className="icon"
                href={SOCIAL_LINKS.facebook}
                {...ExternalLinkProperties}
              >
                <FacebookIcon />
              </a>
              <a
                className="icon"
                href={SOCIAL_LINKS.twitter}
                {...ExternalLinkProperties}
              >
                <TwitterIcon />
              </a>
            </IconWrapper>
          </div>
          <div className="flex-column">
            <span>
              Head Office
              <br />
              PGC House,
              <br />
              273 Paul Kruger Street,
              <br />
              Pretoria, 0001
            </span>
          </div>
        </Wrapper>
      </MainWrapper>
      <Disclaimer>
        <Link
          to="/disclaimer"
          style={{ font: "inherit", textDecoration: "underline" }}
        >
          Website Disclaimer
        </Link>{" "}
        | © 2022 Workerslife. Workerslife Group is a holding company for
        financial services providers Workerslife Insurance Limited (FSP: 4353)
        and Workerslife Assurance Company Limited (FSP: 392) in terms of the
        FAIS Act. The FSCA number is 012 428 8000/ 8012.
      </Disclaimer>
    </Container>
  )
}

export default Footer
