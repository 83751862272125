import React from "react"
import styled from "styled-components"

import { DirectorType } from "./types"

import { Add as PlusIcon } from "../assets/svg"

interface Props {
  color: string
  company: string
  content: DirectorType[]
}

const DirectorList: React.FC<Props> = ({ color, company, content }) => {
  return (
    <Container>
      <Title color={color}>{`${company}\nBoard of Directors`}</Title>
      {content.map(list => (
        <List key={`${list.position}-directors`}>
          {list.holders.map((director, directorIndex) => (
            <NamePlate
              key={`${company}-director-${directorIndex}`}
              className="textContainer"
              color={color}
            >
              <PlusIcon />
              <div>
                <p className="position">{list.position}</p>
                <p className="director">{director}</p>
              </div>
            </NamePlate>
          ))}
        </List>
      ))}
    </Container>
  )
}

export default DirectorList

const Container = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-columns: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    column-gap: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 6fr repeat(2, 3fr);
  }
`

const Title = styled.h2<{ color: string }>`
  color: ${({ color }) => color};
  max-width: 545px;
  white-space: pre-wrap;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 52px;
    grid-column: 1 / span 2;
    line-height: 69px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-column: 1;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`

const NamePlate = styled.div<{ color: string }>`
  display: grid;
  gap: 10px;
  grid-template-columns: 15px 1fr;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    align-self: center;
    display: flex;
    width: 100%;

    rect {
      fill: ${props => props.color};
    }
  }

  p {
    font-size: 16px;

    &.position {
      font-weight: 700;
      line-height: 26px;
    }

    &.director {
      line-height: 24px;
    }
  }
`
