import React from "react"
import styled from "styled-components"

import { Menu } from "../../components"
import { MENU_LINKS } from "../../constants/urls"
import { MenuWrapping } from "./utils"

const ProductMenu = (): JSX.Element => (
  <Container>
    <Backdrop />
    {MENU_LINKS.map((productList, index) => (
      <Menu key={`offers-menu-${index}`} properties={productList} />
    ))}
  </Container>
)

export default ProductMenu

const Container = styled.div`
  ${MenuWrapping}

  display: grid;
  gap: 100px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 60px;
`

const Backdrop = styled.div`
  background-color: #fff;
  opacity: 0.96;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`
