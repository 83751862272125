import React from "react"
import styled, { css } from "styled-components"

import theme from "../theme"

const PALETTE = theme.palette.custom

export interface BasicStyledButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  filled?: boolean
  buttonColor?: string
  textColor?: string
  altColor?: string
}

export interface Props extends BasicStyledButtonProps {
  label: string
  link?: string
  onClick?: () => void
}

const StyledButton: React.FC<Props> = ({
  className,
  label,
  link = null,
  onClick = null,
  filled = false,
  buttonColor = PALETTE.blue,
  textColor,
  altColor,
}) => {
  const ContainerProperties = {
    className: className,
    buttonColor: buttonColor,
    textColor: textColor,
    filled: filled,
    altColor: altColor,
  }

  return link ? (
    <LinkContainer href={link} {...ContainerProperties}>
      {label}
    </LinkContainer>
  ) : (
    <ButtonContainer onClick={onClick || undefined} {...ContainerProperties}>
      {label}
    </ButtonContainer>
  )
}

export default StyledButton

const BaseStyling = css<{
  buttonColor: string
  textColor?: string
  filled: boolean
  altColor?: string
}>`
  ${({ buttonColor, textColor, filled, altColor }) => css`
    background-color: ${filled ? `${buttonColor}` : "transparent"};
    border: 2px solid ${buttonColor};
    color: ${textColor || buttonColor};

    &:hover {
      background-color: ${filled ? "transparent" : buttonColor};
      border: 2px solid ${filled ? altColor : buttonColor};
      color: ${altColor || (filled ? buttonColor : "#fff")};
      transition: all 0.5s;
    }
  `}

  border-radius: 4px;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 22px;
  padding: 30px 48px 28px 48px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s;

  @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 14px;
    min-width: 240px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    width: max-content;
  }
`

const LinkContainer = styled.a`
  ${BaseStyling}
`

const ButtonContainer = styled.button`
  ${BaseStyling}
`
