import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Add as Expand, Remove as Collapse } from "../assets/svg"
import AnimateHeight from "react-animate-height"

interface ICollapsibleListProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string
  title: string
}

const CollapsibleList: React.FunctionComponent<ICollapsibleListProps> = ({
  id,
  title,
  children,
}) => {
  const [collapsed, setCollapsed] = useState(true)
  const [height, setHeight] = useState<"auto" | number>(0)
  useEffect(() => {
    if (location.hash == "#how-to-claim" && collapsed && id == "how-to-claim") {
      setCollapsed(!collapsed)
      setHeight("auto")
    }
  }, [])
  return (
    <Container id={id} className="itemContainer">
      <ListItem
        aria-expanded={height !== 0}
        aria-controls="example-panel"
        onClick={() => {
          setHeight(height === 0 ? "auto" : 0), setCollapsed(!collapsed)
        }}
      >
        <HeaderText>
          <HeaderIcon>{collapsed ? <Expand /> : <Collapse />}</HeaderIcon>
          {title}
        </HeaderText>
      </ListItem>
      <AnimateHeight duration={500} height={height} className="childContainer">
        {children}
      </AnimateHeight>
      <hr color="#B0B0B0" />
    </Container>
  )
}

export default CollapsibleList

const Container = styled.div`
  scroll-margin: 160px;

  &:last-of-type hr {
    display: none;
  }

  hr {
    border-top-width: 0;
    margin: 16px 0;
  }

  .childContainer {
    margin-left: 40px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .childContainer {
      margin-left: 75px;
    }
  }
`

const HeaderText = styled.div`
  display: inline-flex;
  font-family: "PlayfairDisplay";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  padding: 1.5rem 0 1.5rem 0;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: 0px;
    font-size: 24px;
  }
`

const ListItem = styled.div`
  cursor: pointer;
`

const HeaderIcon = styled.div`
  padding-right: 1.8rem;
  padding-top: 4px;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-right: 20px;
    padding-top: 10px;
  }
`
